import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';
import * as firebase from "firebase/app";
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getMarcas, getModelos, getAnoModelos, getVersoes, getVersaoIntegradores } from '../../services/VeiculoService';
import Autosuggest from 'react-autosuggest';
import CurrencyInput from 'react-currency-input';

// Início autocomplete Marcas
const getSuggestionsMarca = (suggestions, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;  
    return inputLength === 0 ? [] : suggestions.filter(lang =>
      lang.marca.toLowerCase().slice(0, inputLength) === inputValue
    );
};
const renderSuggestionMarca = suggestion => (
    <div>
      {suggestion.marca}
    </div>
);
// Fim autocomplete Marcas

// Início autocomplete Modelos
const getSuggestionsModelo = (suggestions, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;  
    return inputLength === 0 ? [] : suggestions.filter(lang =>
      lang.modelo.toLowerCase().slice(0, inputLength) === inputValue
    );
};
const renderSuggestionModelo = suggestion => (
    <div>
      {suggestion.modelo}
    </div>
);
// Fim autocomplete Modelos

// Início autocomplete Versao
const getSuggestionsVersao = (suggestions, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;  
    return inputLength === 0 ? [] : suggestions.filter(lang =>
      lang.versao.toLowerCase().slice(0, inputLength) === inputValue
    );
};
const renderSuggestionVersao = suggestion => (
    <div>
      {suggestion.versao}
    </div>
);
// Fim autocomplete Versao


// Início grid imagens
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 4;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  width: '20%',
  background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
  margin: '20px 15px'
});
// Fim grid imagens


class RevendaVeiculosAlterar extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            upload_preset: "",
            locais_anuncio: [],
            opcionais: [],
            imagens: [],
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vetipoveiculo: "",
            vevalor: "",
            vevalor_mask: "",
            vecodigo_interno: "",
            isUploading: false,
            listMarcas: [],
            suggestionsMarcas: [],
            listModelos: [],
            suggestionsModelos: [],
            listAnoFabricacao: [],
            listAnoModelos: [],
            strAnoModeloSelecionada: "",
            listVersoes: [],
            suggestionsVersoes: [],
            ve_modelo: "", 
            ve_versao: "",
            veipva_pago: false,
            veblindado: false,
            vegarantia_fabrica: false,
            veexibir_site: true,
            validTipoVeiculo: true,
            validMarca: true,
            validModelo: true,
            validAnoFabricacao: true,
            validAnoModelo: true,
            validVersao: true,
            validValor: true,
            validPlaca: true,
            validKm: true,
            validCor: true,
            validCarroceria: true,
            plano: "",
            recursos: "",
            checkedItems: new Map(),
            checkedLocaisAnuncio: new Map(),
            buscandoVersao: false,
            vecarroceria: "",
            veveiculonovo: 0,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.onDragEnd = this.onDragEnd.bind(this);
        
        this.handleChecked = this.handleChecked.bind(this);
        this.handleCheckedLocaisAnuncio = this.handleCheckedLocaisAnuncio.bind(this);        
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeValue  = this.handleChangeValue.bind(this);
        this.handleChangeKm  = this.handleChangeKm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFiles  = this.handleFiles.bind(this);
        this.goBack       = this.goBack.bind(this);
        this.handleChangeTipoVeiculo    = this.handleChangeTipoVeiculo.bind(this);        
        this.handleChangeAnoModelo      = this.handleChangeAnoModelo.bind(this);        
        this.handleChangeVersaoIntegrador = this.handleChangeVersaoIntegrador.bind(this);    
        this.getSuggestionValueMarca    = this.getSuggestionValueMarca.bind(this);        
        this.getSuggestionValueModelo   = this.getSuggestionValueModelo.bind(this);        
        this.getSuggestionValueVersao   = this.getSuggestionValueVersao.bind(this);            
        this.gerarListaAno              = this.gerarListaAno.bind(this);  

    }

    // Início Suggestions Marca
    getSuggestionValueMarca = (suggestion) => {
        this.getModelos(this.state.vetipoveiculo, suggestion.marca);
        return suggestion.marca;
    };
    onChangeMarca = (event, { newValue }) => {
        this.setState({
            vefipe_marca: newValue
        });
    };
    onSuggestionsFetchRequestedMarca = ({ value }) => {
        this.setState({
            suggestionsMarcas: getSuggestionsMarca(this.state.suggestionsMarcas, value)
        });
    };    
    onSuggestionsClearRequestedMarca = () => {
        this.setState({
            suggestionsMarcas: this.state.listMarcas
        });
    };
    // FIM Suggestions Marca


    // Início Suggestions Modelo
    getSuggestionValueModelo = (suggestion) => {
        this.getAnoModelos(this.state.vetipoveiculo, this.state.vefipe_marca, suggestion.modelo);
        return suggestion.modelo;
    };
    onChangeModelo = (event, { newValue }) => {
        this.setState({
            ve_modelo: newValue
        });
    };
    onSuggestionsFetchRequestedModelo = ({ value }) => {
        this.setState({
            suggestionsModelos: getSuggestionsModelo(this.state.suggestionsModelos, value)
        });
    };    
    onSuggestionsClearRequestedModelo = () => {
        this.setState({
            suggestionsModelos: this.state.listModelos
        });
    };
    // FIM Suggestions Modelo


    // Início Suggestions Versao
    getSuggestionValueVersao = (suggestion) => {
        this.getVersaoIntegradores();
        return suggestion.versao;
    };
    onChangeVersao = (event, { newValue }) => {
        this.setState({
            ve_versao: newValue
        });
    };
    onSuggestionsFetchRequestedVersao = ({ value }) => {
        this.setState({
            suggestionsVersoes: getSuggestionsVersao(this.state.suggestionsVersoes, value)
        });
    };    
    onSuggestionsClearRequestedVersao = () => {
        this.setState({
            suggestionsVersoes: this.state.listVersoes
        });
    };
    // FIM Suggestions Versao

    gerarListaAno = (tipo = "F") => {
        var year = new Date().getFullYear();
        if(tipo === "M"){
            year = year + 1;
        }
        var list_year = [];
        for (let index = 1950; index <= year; index++) {
            list_year.push({ano_modelo: index});            
        }
        if(tipo === "M"){
            this.setState({
                listAnoModelos: list_year
            });
        }else{
            this.setState({
                listAnoFabricacao: list_year
            });
        }
    }

    goBack(){
        this.props.history.goBack();
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const imagens = reorder(
          this.state.imagens,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
            imagens,
        });
      }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    upload_preset: doc.data().transformation,
                    plano: doc.data().plano,
                    recursos: doc.data().recursos,
                });
            }
        }).catch(function(error) {
        });

        this.gerarListaAno("F");
        this.gerarListaAno("M");

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {                
                this.setState({
                    locais_anuncio: doc.data().locais_anuncio,
                    // opcionais: doc.data().opcionais,
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor.toLowerCase(),
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    ve_modelo: doc.data().vefipe_name.substr(0, doc.data().vefipe_name.indexOf(" ")).trim(),
                    ve_versao: doc.data().vefipe_name.substr(doc.data().vefipe_name.indexOf(" "), doc.data().vefipe_name.length).trim(),
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vetipoveiculo: doc.data().vetipoveiculo,
                    vevalor: doc.data().vevalor,
                    vevalor_mask: formatMoney(doc.data().vevalor),
                    vecodigo_interno: doc.data().vecodigo_interno ? doc.data().vecodigo_interno : "",
                    veipva_pago: doc.data().veipva_pago !== undefined ? doc.data().veipva_pago : false,
                    veblindado: doc.data().veblindado !== undefined ? doc.data().veblindado : false,
                    vegarantia_fabrica: doc.data().vegarantia_fabrica !== undefined ? doc.data().vegarantia_fabrica : false,
                    veexibir_site: doc.data().veexibir_site !== undefined ? doc.data().veexibir_site : true,
                    vecarroceria: doc.data().vecarroceria !== undefined ? doc.data().vecarroceria : "",
                    veveiculonovo: doc.data().veveiculonovo !== undefined ? parseInt(doc.data().veveiculonovo) : 0,
                });

                const opcionais_veiculo = doc.data().opcionais;
                firestore.collection("opcionais").orderBy("ordenacao", "desc").orderBy("descricao", "asc")
                .onSnapshot(querySnapshot => {
                    var items = [];
                    querySnapshot.forEach(doc => {
                        var document = doc.data();
                        document.id  = doc.id;
                        document.checked = false;
                        opcionais_veiculo.forEach(o => {
                            if(o.id === document.id){
                                document.checked = true;
                                this.setState((prevState) => { prevState.checkedItems.set(o.id, true) });
                            }
                        });
                        items.push(document);
                        
                    });
                    if(this.mounted) {
                        this.setState({
                            opcionais: items
                        });
                    }
                });

                const locais_veiculo = doc.data().locais_anuncio;
                firestore.collection("revenda").doc(this.state.idRevenda).collection("locais_anuncio_revenda").orderBy("ordenacao", "desc").orderBy("descricao", "asc")
                .onSnapshot(querySnapshot => {
                    var items = [];
                    querySnapshot.forEach(doc => {
                        var document = doc.data();
                        document.id  = doc.id;
                        document.checked = false;
                        locais_veiculo.forEach(o => {
                            if(o.idLocal === document.idLocal){
                                document.checked = true;
                                this.setState((prevState) => { prevState.checkedLocaisAnuncio.set(o.idLocal, true) });
                            }
                        });
                        items.push(document);                        
                    });
                    if(this.mounted) {
                        this.setState({
                            locais_anuncio: items
                        });
                    }
                });

            }
        }).catch(function(error) {
        });

    }

    handleChecked(event) {
        const item = event.target.name; 
        const isChecked = event.target.checked;
        this.setState((prevState) => { prevState.checkedItems.set(item, isChecked) });
    }

    handleCheckedLocaisAnuncio(event) {
        const item = event.target.name; 
        const isChecked = event.target.checked;
        this.setState((prevState) => { prevState.checkedLocaisAnuncio.set(item, isChecked) });
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.type === 'checkbox' ? target.checked : target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeValue(event, maskedvalue, floatvalue){
        this.setState({
            vevalor: floatvalue,
            vevalor_mask: maskedvalue,
        });
    }

    handleChangeKm(event, maskedvalue, floatvalue){
        this.setState({
            vequilometragem: maskedvalue,
        });
    }

    handleChangeTipoVeiculo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;
        
        this.getMarcas(value);

        this.setState({
            [name]: value
        });

    }

    handleChangeAnoModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;
        
        this.getVersoes(this.state.vetipoveiculo, this.state.vefipe_marca, this.state.ve_modelo, value);

        this.setState({
            [name]: value
        });

    }

    handleChangeVersaoIntegrador(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        var locais = this.state.locais_anuncio;
        locais[name].codigoVersao = value;

        this.setState({
            locais_anuncio: locais
        });

    }

    getMarcas = async (tipoVeiculo) => { 
        const response = await getMarcas(tipoVeiculo) 
        if(response.status === "success"){
            this.setState({
                listMarcas: response.data,
                suggestionsMarcas: response.data
            });
        }
    } 

    getModelos = async (tipoVeiculo, marca) => { 
        const response = await getModelos(tipoVeiculo, marca) 
        if(response.status === "success"){
            this.setState({
                listModelos: response.data,
                suggestionsModelos: response.data
            });
        }
    } 

    getAnoModelos = async (tipoVeiculo, marca, modelo) => { 
        const response = await getAnoModelos(tipoVeiculo, marca, modelo) 
        if(response.status === "success"){
            let list = response.data;
            if(response.data.length > 0){
                let items = [...response.data];
                let item = {...items[0]};
                item.ano_modelo = item.ano_modelo - 1;
                const new_list  = [];
                new_list.push(item);
                list = new_list.concat(items);
                this.setState({
                    listAnoFabricacao: list,
                    listAnoModelos: response.data
                });
            }else{
                this.gerarListaAno("F");
                this.gerarListaAno("M");
            }            
        }else{
            this.gerarListaAno("F");
            this.gerarListaAno("M");
        }
    } 

    getVersoes = async (tipoVeiculo, marca, modelo, anoModelo) => { 
        const response = await getVersoes(tipoVeiculo, marca, modelo, anoModelo) 
        if(response.status === "success"){
            this.setState({
                listVersoes: response.data,
                suggestionsVersoes: response.data
            });
        }
    } 

    getVersaoIntegradores = async () => { 

        this.setState({
            buscandoVersao: true
        });

        var veiculo_data = {
            "vetipoveiculo": this.state.vetipoveiculo,
            "vefipe_marca": this.state.vefipe_marca,
            "vefipe_name": this.state.ve_modelo.trim() + " " + this.state.ve_versao.trim(),
            "veano_fabricacao": this.state.veano_fabricacao,
            "vefipe_ano_modelo": this.state.vefipe_ano_modelo,
        };

        var locais_anuncio = [];
        this.state.locais_anuncio.forEach(l => {
            var new_l = {
                "descricao": l["descricao"],
                "id": l["id"],
                "idLocal": l["idLocal"],
                "ordenacao": l["ordenacao"],
                "selected": true,
                "selecionaVersao": true,
            };
            locais_anuncio.push(new_l);
        });

        const response = await getVersaoIntegradores(this.state.idRevenda, veiculo_data, locais_anuncio);
        if(response.status === "success"){

            this.state.checkedLocaisAnuncio.forEach((local, id) => {
                if(local){
                    response.data.forEach(o => {
                        if(o.idLocal === id){
                            o.checked = true;
                        }
                    });
                }        
            });

            this.setState({
                locais_anuncio: response.data
            });
        }

        this.setState({
            buscandoVersao: false
        });

    } 

    createFileName(fileName){
        const extensao = fileName.split('.').pop();
        var d = new Date(),
        n = d.getTime(),
        newFileName = n + "." + extensao;
        return newFileName;
    }

    handleFiles(event) {

        this.setState({isUploading: true});

        const target   = event.target;
        const fileList = target.files;

        var count = 0;
        Array.from(fileList).forEach(function (file) {

            const upload_preset  = this.state.upload_preset == null ? "default" : this.state.upload_preset;
            let newImagens     = this.state.imagens;
            let fileName       = "";
            let fileUrlImage   = "";
            let fileThumbImage = "";

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {

                let body_post = new URLSearchParams({
                    "upload_preset": upload_preset,
                    "file": reader.result
                })

                fetch("https://api.cloudinary.com/v1_1/intermedio/image/upload", { 
                    method: "POST", 
                    headers: { 
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }, 
                    body: body_post
                })
                .then(response => {

                    response.json()
                    .then(data => {

                        fileName = count + data.asset_id + "." + data.format;
                        if(data.eager.length === 2 && data.eager[0].secure_url !== undefined && data.eager[1].secure_url !== undefined){
                            fileUrlImage = data.eager[0].secure_url;
                            fileThumbImage = data.eager[1].secure_url;
                        }else{
                            alert("Erro ao fazer upload de imagem.");
                            if(data.eager[0].status !== undefined && data.eager[0].status === 'failed' && data.eager[0].reason !== undefined){
                                alert(data.eager[0].reason);
                            }
                            if(data.eager[1].status !== undefined && data.eager[1].status === 'failed' && data.eager[1].reason !== undefined){
                                alert(data.eager[1].reason);
                            }
                            this.setState({isUploading: false});
                        }

                        newImagens.push({ fileName: fileName, fileUrl: fileUrlImage, fileThumb: fileThumbImage, imagem: fileName, public_id: data.public_id, asset_id: data.asset_id });

                        this.setState({imagens: newImagens});
                        if(count === fileList.length){
                            this.setState({isUploading: false});
                        }

                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({isUploading: false});
                    });        

                })
                .catch(error => {
                    console.log(error);
                    this.setState({isUploading: false});
                });

            }
            
            count++;

        }.bind(this));

    }

    handleSubmit(event) {

        this.setState({
            validTipoVeiculo: true,
            validMarca: true,
            validModelo: true,
            validAnoFabricacao: true,
            validAnoModelo: true,
            validVersao: true,
            validValor: true,
            validPlaca: true,
            validKm: true,
            validCor: true,
            validCarroceria: true,
        });

        var testeValid = 0;
            
        if(this.state.vetipoveiculo.trim() === ""){
            this.setState({
                validTipoVeiculo: false
            });
            testeValid++;
        }
        if(this.state.vefipe_marca.trim() === ""){
            this.setState({
                validMarca: false
            });
            testeValid++;
        } 
        if(this.state.ve_modelo.trim() === ""){
            this.setState({
                validModelo: false
            });
            testeValid++;
        } 
        if(this.state.veano_fabricacao === ""){
            this.setState({
                validAnoFabricacao: false
            });
            testeValid++;
        } 
        if(this.state.vefipe_ano_modelo === ""){
            this.setState({
                validAnoModelo: false
            });
            testeValid++;
        } 
        if(this.state.ve_versao.trim() === ""){
            this.setState({
                validVersao: false
            });
            testeValid++;
        } 
        if(this.state.vevalor < 1){
            this.setState({
                validValor: false
            });
            testeValid++;
        } 
        if(parseInt(this.state.veveiculonovo) === 0){
            if(this.state.vequilometragem.trim() === ""){
                this.setState({
                    validKm: false
                });
                testeValid++;
            } 
            if(this.state.veiplaca.trim().length < 7){
                this.setState({
                    validPlaca: false
                });
                testeValid++;
            }
        }
        if(this.state.vetipoveiculo.trim() === "carro"){
            if(this.state.vecarroceria.trim() === ""){
                this.setState({
                    validCarroceria: false
                });
                testeValid++;
            } 
        }
        if(this.state.vecor.trim() === ""){
            this.setState({
                validCor: false
            });
            testeValid++;
        } 
             
        if(testeValid === 0){

            this.setState({estaGravando: true});

            var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
            let new_opcionais = [];
            let new_locais_anuncio = [];

            this.state.checkedItems.forEach((opcional, id) => {
                if(opcional){
                    this.state.opcionais.forEach(o => {
                        if(o.id === id){
                            var opc = {
                                descricao: o.descricao,
                                id: o.id,
                                ordenacao: parseInt(o.ordenacao),
                            }
                            new_opcionais.push(opc);
                        }
                    });
                }        
            });

            this.state.checkedLocaisAnuncio.forEach((local, id) => {
                if(local){
                    this.state.locais_anuncio.forEach(o => {
                        if(o.idLocal === id){
                            var loc = {
                                descricao: o.descricao,
                                id: o.id,
                                idLocal: o.idLocal,
                                ordenacao: parseInt(o.ordenacao),
                                codigoMarca: o.codigoMarca !== undefined ? o.codigoMarca : null,
                                codigoModelo: o.codigoModelo !== undefined ? o.codigoModelo : null,
                                codigoVersao: o.codigoVersao !== undefined ? o.codigoVersao : null,
                                selecionaVersao: true,
                                selected: true,
                                versoes: null
                            }
                            new_locais_anuncio.push(loc);
                        }
                    });
                }        
            });

            var veiculo = {
                updated_id: user.id,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                opcionais: new_opcionais,
                locais_anuncio: new_locais_anuncio,
                vealterado: 1,
                vevalidacaoalterado: 0,
                veano_fabricacao: this.state.veano_fabricacao,
                vecambio: this.state.vecambio,
                vecor: this.state.vecor,
                vefipe_ano_modelo: this.state.vefipe_ano_modelo,
                vefipe_combustivel: this.state.vefipe_combustivel,
                vefipe_marca: this.state.vefipe_marca.trim(),
                vefipe_name: this.state.ve_modelo.trim() + " " + this.state.ve_versao.trim(),
                veiplaca: this.state.veiplaca,
                veobservacao: this.state.veobservacao,
                veportas: this.state.veportas,
                vequilometragem: this.state.vequilometragem.toString(),
                vetipoveiculo: this.state.vetipoveiculo,
                imagens: this.state.imagens,
                vecodigo_interno: this.state.vecodigo_interno,
                vevalor: this.state.vevalor.toString(),
                ve_modelo: this.state.ve_modelo.trim(),
                ve_versao: this.state.ve_versao.trim(),
                veipva_pago: this.state.veipva_pago,
                veblindado: this.state.veblindado,
                vegarantia_fabrica: this.state.vegarantia_fabrica,
                veexibir_site: this.state.veexibir_site,  
                vecarroceria: this.state.vecarroceria,
                veveiculonovo: parseInt(this.state.veveiculonovo)
            }

            firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).set(veiculo, { merge: true })
            .then(res => {             
                this.setState({returnPagina: true, estaGravando: false});
            })
            .catch(err => { 
                this.setState({returnPagina: true, estaGravando: false});     
            });

        }

        event.preventDefault();

    }

    removerImagem(index){
        if(index > -1){
            var newImagens = this.state.imagens;
            newImagens.splice(index, 1);
            this.setState({imagens: newImagens});
        }
    }

    createListLocaisAnuncio = () => {

        const listItems = this.state.locais_anuncio.map((element, id) =>
            <p className="checkbox-item" key={id}>
                <label className="checkbox-label">
                    <input type="checkbox" name={element.idLocal} defaultChecked={element.checked} onClick={this.handleCheckedLocaisAnuncio} /> 
                    &nbsp;&nbsp;{element.descricao}<br/>
                    {
                        element.versoes !== undefined && element.versoes !== null && element.versoes.length > 0 ?
                            <select className="form-ui-bootstrap" name={id} onChange={this.handleChangeVersaoIntegrador} >
                                <option value="">Selecione versão {element.descricao}...</option> 
                                { 
                                    element.versoes.map((v, id2) =>
                                        <option key={id + "-" +id2} value={v.codigo_versao} >{ v.versao }</option>
                                    ) 
                                }
                            </select>
                        : null
                    }
                </label>
                
            </p>
        );

        return (
            <div className="form-group">{listItems}</div>
        );

    }

    render(){
        const { 
            estaGravando, 
            nome_revenda,
            locais_anuncio,
            imagens,
            opcionais,
            veano_fabricacao,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            veiplaca,
            veobservacao,
            veportas,
            vequilometragem,
            vetipoveiculo,
            vevalor_mask,
            isUploading,
            vecodigo_interno,
            ve_modelo, 
            ve_versao,
            suggestionsMarcas,
            suggestionsModelos,
            listAnoFabricacao,
            listAnoModelos,
            suggestionsVersoes,
            veipva_pago,
            veblindado,
            vegarantia_fabrica,
            veexibir_site,
            validTipoVeiculo,
            validMarca,
            validModelo,
            validAnoFabricacao,
            validAnoModelo,
            validVersao,
            validValor,
            validPlaca,
            validKm,
            recursos,
            buscandoVersao,
            vecarroceria,
            veveiculonovo,
            validCor,
            validCarroceria
        } = this.state;
        if(this.state.returnPagina){
            var link = "/revenda/"+this.state.idRevenda+"/veiculos/listar";
            return <Redirect to={link} />;
        }
        const inputPropsMarca = {
            placeholder: '',
            value: vefipe_marca,
            onChange: this.onChangeMarca
        };
        const inputPropsModelo = {
            placeholder: '',
            value: ve_modelo,
            onChange: this.onChangeModelo
        };
        const inputPropsVersao = {
            placeholder: '',
            value: ve_versao,
            onChange: this.onChangeVersao
        };

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Alterar veículo
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Código"
                                                        name="vecodigo_interno"
                                                        onChange={this.handleChange}
                                                        value={vecodigo_interno}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Tipo veículo *</label>
                                                    <select className="form-ui-bootstrap" name="vetipoveiculo" onChange={this.handleChangeTipoVeiculo} value={ vetipoveiculo }>
                                                        <option value=""></option>
                                                        <option value="carro">Carro</option>
                                                        <option value="moto">Moto</option>
                                                        <option value="caminhao">Caminhão</option>
                                                        <option value="outros">Outros</option>
                                                    </select>
                                                    {
                                                        validTipoVeiculo ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Marca *</label><br/>    
                                                    <Autosuggest
                                                        suggestions={suggestionsMarcas}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedMarca}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedMarca}
                                                        getSuggestionValue={this.getSuggestionValueMarca}
                                                        renderSuggestion={renderSuggestionMarca}
                                                        inputProps={inputPropsMarca}
                                                    />
                                                    {
                                                        validMarca ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Modelo *</label><br/>    
                                                    <Autosuggest
                                                        suggestions={suggestionsModelos}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedModelo}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedModelo}
                                                        getSuggestionValue={this.getSuggestionValueModelo}
                                                        renderSuggestion={renderSuggestionModelo}
                                                        inputProps={inputPropsModelo}
                                                    />
                                                    {
                                                        validModelo ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Ano fabricação *</label>
                                                    <select className="form-ui-bootstrap" name="veano_fabricacao" onChange={this.handleChange} value={ veano_fabricacao }>
                                                        <option value=""></option>
                                                        {
                                                            listAnoFabricacao.map((element, id) =>
                                                                <option key={element.ano_modelo} value={element.ano_modelo}>{element.ano_modelo}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        validAnoFabricacao ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Ano modelo *</label>
                                                    <select className="form-ui-bootstrap" name="vefipe_ano_modelo" onChange={this.handleChangeAnoModelo} value={ vefipe_ano_modelo }>
                                                        <option value=""></option>
                                                        {
                                                            listAnoModelos.map((element, id) =>
                                                                <option key={element.ano_modelo} value={element.ano_modelo}>{element.ano_modelo}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        validAnoModelo ? null : <p className="error">Preenchimento obrigatório</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Versão *</label><br/>    
                                                    <Autosuggest
                                                        suggestions={suggestionsVersoes}
                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedVersao}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedVersao}
                                                        getSuggestionValue={this.getSuggestionValueVersao}
                                                        renderSuggestion={renderSuggestionVersao}
                                                        inputProps={inputPropsVersao}
                                                    />
                                                    {
                                                        validVersao ? null : <p className="error">Preenchimento obrigatório</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                veveiculonovo === 1 ? null :
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <TextValidator
                                                                label="Quilometragem *"
                                                                onChange={this.handleChange}
                                                                name="vequilometragem"
                                                                value={vequilometragem}
                                                                className="form-ui-bootstrap"
                                                                validators={['minNumber:0', 'matchRegexp:^[0-9]{1,}$']}
                                                                errorMessages={['Km inválido', 'Km inválido']}
                                                            />
                                                            {
                                                                validKm ? null : <p className="error">Preenchimento obrigatório</p>
                                                            } 
                                                        </div>
                                                    </div> 
                                            }                                             
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Preço *</label><br/>    
                                                    <CurrencyInput 
                                                        onChangeEvent={this.handleChangeValue}
                                                        label="Preço *"
                                                        name="vevalor"
                                                        value={vevalor_mask}
                                                        className="form-ui-bootstrap"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="2"
                                                        prefix="R$ "
                                                    />
                                                    {
                                                        validValor ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>                                            
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label={veveiculonovo === 1 ? "Placa" : "Placa *"}
                                                        onChange={this.handleChange}
                                                        name="veiplaca"
                                                        value={veiplaca}
                                                        inputProps = {{maxLength:7}}
                                                        className="form-ui-bootstrap"
                                                    />
                                                    {
                                                        validPlaca ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>    
                                        </div>
                                        <div className="row">                                      
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label>Combustível *</label>
                                                    <select className="form-ui-bootstrap" name="vefipe_combustivel" onChange={this.handleChange} value={ vefipe_combustivel }>
                                                        <option value="flex">Flex</option>
                                                        <option value="alcool">Álcool</option>
                                                        <option value="gasolina">Gasolina</option>
                                                        <option value="diesel">Diesel</option>
                                                        <option value="gnv">GNV</option>
                                                        <option value="eletrico">Elétrico</option>
                                                        <option value="hibrido">Híbrido</option>
                                                        <option value="oleo">Óleo</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Câmbio *</label>
                                                    <select className="form-ui-bootstrap" name="vecambio" onChange={this.handleChange} value={ vecambio }>
                                                        <option value="A">Automático</option>
                                                        <option value="M">Manual</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Portas *</label>
                                                    <select className="form-ui-bootstrap" name="veportas" onChange={this.handleChange} value={ veportas }>
                                                        <option value="0">0</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Cor *</label>
                                                    <select className="form-ui-bootstrap" name="vecor" onChange={this.handleChange} value={ vecor }>
                                                        <option value=""></option>
                                                        <option value="amarelo">Amarelo</option>
                                                        <option value="azul">Azul</option>
                                                        <option value="bege">Bege</option>
                                                        <option value="branco">Branco</option>
                                                        <option value="bronze">Bronze</option>
                                                        <option value="cinza">Cinza</option>
                                                        <option value="dourado">Dourado</option>
                                                        <option value="indefinida">Indefinida</option>
                                                        <option value="laranja">Laranja</option>
                                                        <option value="marrom">Marrom</option>
                                                        <option value="prata">Prata</option>
                                                        <option value="preto">Preto</option>
                                                        <option value="rosa">Rosa</option>
                                                        <option value="roxo">Roxo</option>
                                                        <option value="verde">Verde</option>
                                                        <option value="vermelho">Vermelho</option>
                                                        <option value="vinho">Vinho</option>
                                                    </select>
                                                    {
                                                        validCor ? null : <p className="error">Preenchimento obrigatório</p>
                                                    }
                                                </div>
                                            </div>                                            
                                            {
                                                vetipoveiculo === "carro" ?
                                                    <div className="col-lg-3">
                                                        <div className="form-group">
                                                            <label>Carroceria *</label>
                                                            <select className="form-ui-bootstrap" name="vecarroceria" onChange={this.handleChange} value={ vecarroceria }>
                                                                <option value=""></option>
                                                                <option value="Sedan">Sedan</option>
                                                                <option value="Hatch">Hatch</option>
                                                                <option value="SUV">SUV</option>
                                                                <option value="Passeio">Passeio</option>
                                                                <option value="Conversível">Conversível</option>
                                                                <option value="Pick-up">Pick-up</option>
                                                                <option value="Antigo">Antigo</option>
                                                                <option value="Buggy">Buggy</option>
                                                                <option value="Van/Utilitário">Van/Utilitário</option>
                                                            </select>
                                                            {
                                                                validCarroceria ? null : <p className="error">Preenchimento obrigatório</p>
                                                            } 
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Observações</label>
                                                    <textarea name="veobservacao" className="form-ui-bootstrap" value={veobservacao} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Opcionais</label>
                                                    { 
                                                        opcionais.map(element => ( 
                                                            <p className="checkbox-item" key={element.id}>
                                                                <label className="checkbox-label">
                                                                    <input type="checkbox" name={element.id} defaultChecked={element.checked} onChange={this.handleChecked} /> &nbsp;&nbsp;{element.descricao}
                                                                </label>
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Extra</label>
                                                    <p className="checkbox-item">
                                                        <label className="checkbox-label">
                                                            <input type="checkbox" name="veipva_pago" checked={veipva_pago} onChange={this.handleChange} /> 
                                                            &nbsp;&nbsp;IPVA Pago
                                                        </label>
                                                    </p>
                                                    <p className="checkbox-item">
                                                        <label className="checkbox-label">
                                                            <input type="checkbox" name="veblindado" checked={veblindado} onChange={this.handleChange} /> 
                                                            &nbsp;&nbsp;Blindado
                                                        </label>
                                                    </p>
                                                    <p className="checkbox-item">
                                                        <label className="checkbox-label">
                                                            <input type="checkbox" name="vegarantia_fabrica" checked={vegarantia_fabrica} onChange={this.handleChange} /> 
                                                            &nbsp;&nbsp;Garantia de fábrica
                                                        </label>
                                                    </p>
                                                    {
                                                        recursos !== undefined && recursos.site_revenda === true ?
                                                            <p className="checkbox-item">
                                                                <label className="checkbox-label">
                                                                    <input type="checkbox" name="veexibir_site" checked={veexibir_site} onChange={this.handleChange} /> 
                                                                    &nbsp;&nbsp;Exibir veículo site revenda
                                                                </label>
                                                            </p>
                                                        : null
                                                    }
                                                    <br/><br/><label>Locais de anúncio</label>
                                                    {
                                                        buscandoVersao ? 
                                                            <div><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                        :                                                             
                                                            this.createListLocaisAnuncio()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Carregar imagens</label>
                                                    <div style={{ display: "flex" }}>
                                                        <input type="file" name="novasImagens" id="novasImagens" multiple onChange={this.handleFiles} ></input>
                                                        { isUploading ? 
                                                            <div className="progress progress-upload-images">
                                                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>Carregando imagens...</div>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">                                    
                                            {      
                                                imagens !== undefined && imagens.length > 0 ? 
                                                ( 
                                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                                        <Droppable droppableId="droppable" direction="horizontal">
                                                        {(provided, snapshot) => (
                                                            <div
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                            {...provided.droppableProps}
                                                            >
                                                            {imagens.map((item, index) => (
                                                                <Draggable key={item.imagem} draggableId={item.imagem} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        <div className="alterar-veiculo" >
                                                                            { 
                                                                                item.fileUrl !== null && item.fileThumb !== null ?
                                                                                    ( <div><a target="_blank" title={ item.fileName } rel="noopener noreferrer" href={ item.fileUrl }><img src={ item.fileThumb } alt={ item.fileName } /></a><br/></div> ) 
                                                                                :   ( <div><p>Imagem não carregou no servidor.</p></div> )
                                                                            }
                                                                            <div className="remover-imagem"><a onClick={() => this.removerImagem(index)}>Remover imagem</a></div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                            </div>
                                                        )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                )
                                                : null
                                            }

                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button> <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

export default RevendaVeiculosAlterar