// Dados servidor local
// export const base_url = "http://localhost/intermedio_api/public";
// export const api_key  = "VUdSaUFxeDRPSlVPb1RONnRtZWNYdkhMdk9lMHozaUo1czkzVGVMdw==";

// Dados servidor remoto
export const base_url = "https://api.intermedioveiculos.com.br";
export const api_key  = "VUdSaUFxeDRPSlVPb1RONnRtZWNYdkhMdk9lMHozaUo1czkzVGVMdw==";

// Token firebase para envio de push notification
export const token_key  = "AAAAfIsMOnQ:APA91bG5Q7Y3n4vZMVJUkF_XKM07h6b3Rp0aU874CvHkSN6fPnFVFJg8r9YTxun7ovBofHvOa1tYJOJHpZ62MlnHll3XXot8BiUXQJcMTPaZqJXmE9MyrPkVoeTYo3etVslQ3RQDzrIv";

export const fipe_url = "https://api.ffsistemas.com.br";
// export const fipe_url = "http://localhost/fipe_ws/public";
export const fipe_api_token  = "aosdifadskflnaposdijfklsadnfbiuasdjfi";

export const cloudinary_name       = "intermedio";
export const cloudinary_api_key    = "325711516843826";
export const cloudinary_api_secret = "MtHYeqwl3Yh6tLxDjptZL2f4O7Q";

// Intermédio Veículos Integração
// export const app_instagram = "1205872297424772"; // Teste 
export const app_instagram = "993770105459902"; // Produção 

// Intermédio Veículos Integrador
// export const app_facebook = "3837899493203517"; // Teste 
export const app_facebook = "1356617369061300"; // Produção 

export const versao = "1.6.5";